<template>
  <div class="page-content">
    <div class="header-box mb-24">
      <div class="left">
        <span>企业：</span>
        <el-select class="mr-20" v-model="queryParams.EnterpriseId" clearable>
          <el-option
            v-for="el in enterpriseList"
            :key="el.Id"
            :label="el.EnterpriseName"
            :value="el.Id"
          ></el-option>
        </el-select>
        <span>检查时间：</span>
        <el-date-picker
          v-model="queryParams.Date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd HH:mm:ss"
          class="mr-20"
        >
        </el-date-picker>
        <el-button type="primary" @click="initialize">搜索</el-button>
      </div>
      <div class="right">
        <el-button type="primary" icon="el-icon-upload" @click="handleAdd"
          >新增检查记录</el-button
        >
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      v-loading="loading"
      height="640px"
    >
      <template v-for="(item, index) in tableConfig">
        <el-table-column
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :width="item.prop === 'StudentList' ? '400' : ''"
        >
          <template slot-scope="scope">
            <div v-if="item.prop === 'StudentList'">
              <template v-for="el in scope.row['StudentList']">
                <span :key="el.Id" class="mr-6">{{ el.StudentName }}</span>
              </template>
            </div>

            <span v-else-if="item.prop === 'InspectTime'">{{
              formartDate(scope.row["InspectTime"], "day")
            }}</span>
            <div v-else-if="item.prop === 'FileInfo'" class="file">
              <template>
                <div v-if="scope.row['FileInfo']">
                  <div>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content">
                        <el-button
                          type="text"
                          size="mini"
                          icon="el-icon-camera"
                          @click="handlePreview(scope.row['FileInfo'].FullPath)"
                          >预览 {{ scope.row["FileInfo"].Name }}</el-button
                        >
                        <br />
                        <el-button
                          type="text"
                          size="mini"
                          icon="el-icon-download"
                        >
                          <a
                            class="el-button--text"
                            :href="scope.row['FileInfo'].SavePath"
                            :download="scope.row['FileInfo'].Name"
                            >下载 {{ scope.row["FileInfo"].Name }}</a
                          >
                        </el-button>
                      </div>
                      <div class="el-icon-paperclip">
                        {{
                          scope.row["FileInfo"]
                            ? scope.row["FileInfo"].Name
                            : ""
                        }}
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </div>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column>
      </template>
      <el-table-column label="操作" width="200px">
        <template slot-scope="scoped">
          <el-button
            type="text"
            icon="el-icon-edit-outline"
            class="btn-color"
            @click="handleEdit(scoped.row.Id)"
            >编辑</el-button
          >
          <el-button
            type="text"
            icon="el-icon-delete"
            class="hover-danger"
            @click="handleDelete(scoped.row.Id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :visible.sync="showFile" title="查看文件" width="1100px">
      <Preview v-if="showFile" :fileId="fileId" />
    </el-dialog>
  </div>
</template>

<script>
import Preview from "@/components/Preview";
import { getPageList, deleteInspect } from "@/api/jobInspect";
import { getEnterpriseList } from "@/api/internshipLayout";
import contentTools from "@/mixins/content-tools";
import { StaffTypeEnum } from "@/utils/constant";
import { mapGetters } from "vuex";
export default {
  components: {
    Preview
  },
  mixins: [contentTools],
  computed: {
    ...mapGetters(["employee"]),
    RoleType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    }
  },
  data() {
    return {
      showFile: false,
      loading: false,
      queryParams: {
        PageSize: 999,
        PageIndex: 1,
        Type: 0,
        Date: []
      },

      tableData: [],
      tableConfig: [
        {
          label: "关联学生",
          prop: "StudentList"
        },
        {
          label: "检查名称",
          prop: "Title"
        },
        {
          label: "企业名称",
          prop: "EnterpriseName"
        },
        {
          label: "检查日期",
          prop: "InspectTime"
        },
        {
          label: "检查结果",
          prop: "Content"
        },
        {
          label: "佐证材料",
          prop: "FileInfo"
        }
      ],
      enterpriseList: [],
      studentList: [],
      fileId: ""
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async initialize() {
      try {
        this.loading = true;
        if (this.RoleType === StaffTypeEnum.School) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.SchoolId = temp.Employer.Id;
        } else if (this.RoleType === StaffTypeEnum.Enterprise) {
          let temp = this.employee.find(el => el.StaffType === this.RoleType);
          this.queryParams.EnterpriseId = temp.Employer.Id;
        } else {
          return null;
        }
        await this.getEnterprise();
        await this.getTableData();
      } catch (err) {
        console.log("err:", err);
      } finally {
        this.loading = false;
      }
    },
    async getTableData() {
      if (this.queryParams.Date) {
        let [startTime, endTime] = this.queryParams.Date;
        this.queryParams.StartTime = startTime;
        this.queryParams.EndTime = endTime;
      } else {
        this.queryParams.StartTime = "";
        this.queryParams.EndTime = "";
      }
      const res = await getPageList(this.queryParams);
      if (res.IsSuccess) {
        this.tableData = res.Result.Data;
      }
    },
    handleDelete(id) {
      this.$confirm("此操作将会永久删除此数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const res = await deleteInspect(id);
          if (res.IsSuccess) {
            this.$message.success("删除成功");
            this.initialize();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    async getEnterprise() {
      const res = await getEnterpriseList();
      if (res.IsSuccess) {
        this.enterpriseList = res.Result;
      }
    },
    handleAdd() {
      this.$router.push(
        `/school/practice/inspect/create?schoolId=${this.queryParams.SchoolId}`
      );
    },
    handleEdit(id) {
      this.$router.push(
        `/school/practice/inspect/edit?id=${id}&schoolId=${this.queryParams.SchoolId}`
      );
    },
    handlePreview(el) {
      this.showFile = true;
      this.fileId = el;
    }
  }
};
</script>

<style lang="scss" scoped>
.header-box {
  display: flex;
  justify-content: space-between;
}
.file {
  color: #598DF3;
}
.el-button--text {
  text-decoration: none;
}
</style>
